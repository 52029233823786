<template>
	<div>
		<b-card no-body>
			<b-card header-tag="header">
				<template v-slot:header>
					<product-form-nav :productDetails="productDetails" link="options" :productId="id"></product-form-nav>
				</template>
				<b-card-text>
					<div class="pb-4">
						<h4>
							Product Option / Variant
							<span class="float-right">
								<b-button :to="`/product/option-form/${id}`" variant="primary"><i class="las la-image"></i> New Variant
								</b-button>
							</span>
						</h4>

					</div>
				</b-card-text>
				<DataTable ref="dt" :value="items" dataKey="id" :paginator="true" :rows="10" :filters="filters"
					class="mt-0"
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					:rowsPerPageOptions="[5, 10, 25]"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products">
					<template #empty>
						<div class="text-center text-muted py-5">Options not found.</div>
					</template>

					<Column field="name" header="Option" :sortable="true">
						<template #body="slotProps">
							<div style="min-width:200px">
							<span v-for="(option,i) in slotProps.data.optionValues" :key="option.option_value_id">{{ option.option_value }} <span v-if="slotProps.data.optionValues.length > (i+1)">| </span></span>
						</div>
						</template>
					</Column>

					<Column field="order" header="Quantity" :sortable="true" >
						<template #body="slotProps">
							{{
							slotProps.data.quantity
							}}
						</template>
					</Column>
					<Column field="order" header="Price" :sortable="true" >
						<template #body="slotProps">
							{{
							slotProps.data.price
							}}
						</template>
					</Column>
					<Column field="order" header="Start Date" :sortable="true" >
						<template #body="slotProps">
							{{
							slotProps.data.start_date
							}}
						</template>
					</Column>
					<Column field="order" header="End Date" :sortable="true" >
						<template #body="slotProps">
							{{
							slotProps.data.end_date
							}}
						</template>
					</Column>
					<Column field="order" header="Discount" :sortable="true">
						<template #body="slotProps">
							{{
							slotProps.data.discount
							}}
						</template>
					</Column>
					<Column field="order" header="Status" :sortable="true">
						<template #body="slotProps">
							{{
							slotProps.data.status
							}}
						</template>
					</Column>
					<Column field="action" header="Action" :sortable="true">
						<template #body="slotProps">
							<router-link class="btn btn-sm btn-outline-primary " :to="`/product/option-form/${id}/${slotProps.data.id}`">
								<i class="las la-edit"></i>
							</router-link>
							<button class="btn btn-sm btn-outline-danger ml-2" @click="deleteVariant(slotProps.data.id)">
								<i class="las la-trash"></i>
							</button>
						</template>
					</Column>
				</DataTable>
			</b-card>
		</b-card>
		
	</div>
</template>

<script>
	import ProductFormNav from "./components/ProductFormNav.vue";
	import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
	import DataTable from "primevue/datatable";
	import Column from "primevue/column";
	import Swal from "sweetalert2";

	//api services
	import ProductService from "@/core/services/api/product/product";
	import OptionGroupService from "@/core/services/api/product/option";
	
	export default {
		components: {
			ProductFormNav,
			DataTable,
			Column,
		},
		data() {
			return {
				id: this.$route.params.id?parseInt(this.$route.params.id):null,
				productDetails:{},
				items: [],
				filters: {},
			};
		},
		created (){
			this.getProductById();
			this.getVariantList();
		},
		mounted() {
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					title: "Dashboard",
					route: "/dashboard",
				},
				{
					title: "Products",
					route: "/products",
				},
				{
					title: "Manage Product : Option / Variant",
				},
			]);
			
		},
		methods: {
			async getProductById() {
				var vm = this;
				var response = await ProductService.getProductById(this.id);
				vm.productDetails = response;
			},
			async getVariantList() {
				var vm = this;
				var response = await OptionGroupService.getVariantList(vm.id);
				vm.items = response;
			},
			deleteVariant(id){
				var vm = this;
				Swal.fire({
					icon: 'warning',
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					showCancelButton: true,
					confirmButtonText: 'Yes, delete it!'
				}).then((result) => {
					if (result.isConfirmed) {
						OptionGroupService.deleteVariant(id)
							.then(function (response) {
									vm.getVariantList();
									let timerInterval;
									Swal.fire({
									title: response.message,
									timer: 1000,
									icon: "success",
									timerProgressBar: true,
									showConfirmButton: false,
									showCancelButton: false,
									didOpen: () => {
										Swal.showLoading();
										const b = Swal.getHtmlContainer().querySelector("b");
										timerInterval = setInterval(() => {
										b.textContent = Swal.getTimerLeft();
										}, 100);
									},
									willClose: () => {
										clearInterval(timerInterval);
									},
									}).then((result) => {
									/* Read more about handling dismissals below */
									if (result.dismiss === Swal.DismissReason.timer) {
									}
									});
							})
							.catch(function () {
							});
					}
				});
				
			}
		},
	};
</script>

<style></style>